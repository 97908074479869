<template>
  <b-sidebar id="add-new-user-sidebar" :visible="isAddNewUserSidebarActive" bg-variant="white"
    sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Plan
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(savePlan)" @reset.prevent="resetForm">

          <!-- Title -->
          <validation-provider #default="validationContext" name="Title" rules="required">
            <b-form-group label="Title" label-for="title">
              <b-form-input id="bame" v-model="planData.title" autofocus :state="getValidationState(validationContext)"
                trim placeholder="Basic" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sub Title -->
          <validation-provider #default="validationContext" name="Sub Title">
            <b-form-group label="Sub Title" label-for="sub-title">
              <b-form-input id="bame" v-model="planData.subTitle" autofocus trim placeholder="Council Area" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider #default="validationContext" name="Price" rules="required">
            <b-form-group label="Price" label-for="price">
              <b-form-input id="price" v-model="planData.price" :state="getValidationState(validationContext)" trim />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Active -->
          <validation-provider #default="validationContext" name="Active" rules="required">
            <b-form-group>
              <b-form-checkbox-group id="active" v-model="planData.active" name="active">
                <b-form-checkbox value="active">Active</b-form-checkbox>
              </b-form-checkbox-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider #default="validationContext" name="MonthlyYearly" rules="required">
            <b-form-group label="Plan Type" v-slot="{ ariaDescribedby }">
              <b-form-radio-group id="radio-group-2" :aria-describedby="ariaDescribedby" name="radio-sub-component"
                v-model="planData.monthlyYealy">
                <b-form-radio value="monthly">Monthly</b-form-radio>
                <b-form-radio value="yearly">Yearly</b-form-radio>
              </b-form-radio-group>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div v-if="planData.monthlyYealy == 'yearly'">
            <b-form-group label="MonthlyPrice" label-for="monthlyPrice">
              <b-form-input id="monthlyPrice" v-model="planData.yearlyMonthlyPrice" autofocus trim />
            </b-form-group>

            <b-form-group label="YearlyPrice" label-for="yearlyPrice">
              <b-form-input id="yearlyPrice" v-model="planData.yearlyYearPrice" autofocus trim />
            </b-form-group>
          </div>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
              Add
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormCheckboxGroup, BFormFile, BFormRadioGroup, BFormRadio
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue';

const blankPlanData = {
  name: '',
  slug: '',
  price: '',
  active: null,
  type: null,
  applicable_on: '',
  selectedFile: '',
}

const planData = ref(JSON.parse(JSON.stringify(blankPlanData)))

const resetPlanData = () => {
  planData.value = JSON.parse(JSON.stringify(blankPlanData))
}

const {
  refFormObserver,
  getValidationState,
  resetForm,
} = formValidation(resetPlanData)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormFile,
    BFormRadio,
    BFormRadioGroup,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props:
    [
      'refetch-data',
      'isAddNewUserSidebarActive',
      'roleOptions',
      'planOptions'
    ],
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      selectedFile: "",
      required,
      refFormObserver,
      getValidationState,
      resetForm,
      file1: null,
      file2: null,
      typeOptions: [
        { name: 'Plan', value: 'plan' },
        { name: 'Add On', value: 'add_on' }
      ],
      applicableOptions: [
        { name: 'All', value: 'all' },
      ],
      planData: {}
    }
  },
  mounted() {
    this.planData.benfits = {}
  },
  methods: {
    async savePlan() {
      console.clear();
      console.log('Plan Data ' + this.planData);
      return;
      await this.$axios.post(`${this.$API_URL_PLANS}/savePlan`, {
        data: this.planData
      })
        .then(function (response) {
          if (response.data.success) {
            Vue.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Success`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `User has been added successfully!`,
              },
            });
          } else {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
