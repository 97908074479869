<template>

  <div>

    <plan-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @refetch-data="refetchData" />


    <!-- Filters -->
    <!-- <plans-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
      :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="options.perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                @keyup="fetchPlans()" />
              <!--  -->
              <b-button variant="primary" @click="isAddNewUserSidebarActive = true">
                <span class="text-nowrap">Add Plan</span>
              </b-button>
            </div>
          </b-col>

        </b-row>

      </div>


      <b-table ref="refPlanListTable" primary-key="id" :items="fetchPlan" :fields="tableColumns"
        :sort-by.sync="options.sortBy" :sort-desc.sync="options.sortDesc" :current-page.sync="options.currentPage"
        per-page="0" :filter="null" :api-url="null" show-empty class="position-relative" responsive
        empty-text="No matching records found" sticky-header="70vh" hover :busy="busy" head-variant="light"
        no-sort-reset>

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <b-link :to="{ name: 'plans-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>

        <template #cell(slug)="data">
          <div class="text-nowrap">
            <!-- <feather-icon :icon="'UserIcon'" size="18" class="mr-50" :class="`text-primary`" /> -->
            <span class="align-text-top text-capitalize">{{ data.item.slug }}</span>
          </div>
        </template>

        <template #cell(price)="data">
          <div class="text-nowrap">
            <!-- <feather-icon :icon="'UserIcon'" size="18" class="mr-50" :class="`text-primary`" /> -->
            $<span class="align-text-top text-capitalize">{{ data.item.price }}</span>
          </div>
        </template>

        <template #cell(active)="data">
          <div class="text-nowrap">
            <!-- <feather-icon :icon="'UserIcon'" size="18" class="mr-50" :class="`text-primary`" /> -->
            <span class="align-text-top text-capitalize">{{ data.item.active }}</span>
          </div>
        </template>

        <template #cell(type)="data">
          <div class="text-nowrap">
            <!-- <feather-icon :icon="'UserIcon'" size="18" class="mr-50" :class="`text-primary`" /> -->
            <span class="align-text-top text-capitalize">{{ data.item.type }}</span>
          </div>
        </template>


        <template #cell(Applicable On)="data">
          <div class="text-nowrap">
            <!-- <feather-icon :icon="'UserIcon'" size="18" class="mr-50" :class="`text-primary`" /> -->
            <span class="align-text-top text-capitalize">{{ data.item.applicable_on }}</span>
          </div>
        </template>

        <template #cell(Created At)="data">
          <div class="text-nowrap">
            <!-- <feather-icon :icon="'UserIcon'" size="18" class="mr-50" :class="`text-primary`" /> -->
            <span class="align-text-top text-capitalize">{{ data.item.createdAt }}</span>
          </div>
        </template>



        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <!-- <b-dropdown-item :to="{ name: 'plans-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->

            <b-dropdown-item :to="{ name: 'plans-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deletePlan(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">
                Delete
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>



          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ (options.currentPage - 1) * options.perPage }} to {{
            (options.currentPage - 1) * options.perPage + options.perPage }} of
              {{ totalPlan }} entries</span>
          </b-col>



          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="options.currentPage" :per-page="options.perPage" :total-rows="totalPlan" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>



        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import PlanListAddNew from './PlanListAddNew.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue';

export default {
  components: {
    // PlansListFilters,
    PlanListAddNew,
    ToastificationContent,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  mounted() {
    this.fetchPlans()

    this.roleOptions = [{ label: 'Admin', value: 'admin' },
    { label: 'Author', value: 'author' },
    { label: 'Editor', value: 'editor' },
    { label: 'Maintainer', value: 'maintainer' },
    { label: 'Subscriber', value: 'subscriber' },
    ];

    this.planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ];

    this.statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ];
  },
  data() {
    return {
      totalPlan: 0,
      options: {
        currentPage: 1,
        perPage: 50,
        sortBy: "id",
        sortDesc: true,
        // filter: null,
        // apiUrl: null,
      },
      perPageOptions: [10, 50, 100, 150, 200, 250],
      fetchPlan: [],
      tableColumns: [
        { key: 'Name', sortable: true },
        { key: 'Slug', sortable: true },
        { key: 'Price', sortable: true },
        {
          key: 'active',
          label: 'Active',
          sortable: true,
        },
        { key: 'Type', sortable: true },
        {
          key: 'applicable_on',
          label: 'Applicable On',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Created At',
          sortable: true,
        },
        { key: 'actions' }
      ],
      busy: false,
      // isAddNewUserSidebarActive: false,
      roleOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [],
      isAddNewUserSidebarActive: false,
      searchQuery: '',
      busy: false
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        // this.fetchPlans();
      },
    },
  },
  methods: {
    async fetchPlans() {

      try {

        let fetchPlan = await this.$axios.post(`${this.$API_URL_PLANS}/fetchPlansAdmin`,
          {
            filters: this.filters,
            options: this.options,
            search: this.searchQuery
          })

        if (fetchPlan.data.success) {
          this.fetchPlan = fetchPlan.data.response.rows;
          this.totalPlan = fetchPlan.data.response.count;
        } else {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'EditIcon',
              variant: 'danger',
              text: `${fetchPlan.data.response}`
            },
          })
        }
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'EditIcon',
            variant: 'danger',
            text: `Something went wrong`
          },
        })
      }

    },
    deletePlan(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete this plan ?", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$axios
              .get(`${this.$API_URL_PLANS}/deletePlan/${id}`)
              .then((plans) => {

                if (plans.data.success) {
                  Vue.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Success`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `User has been added successfully!`,
                    },
                  });

                  // refPlanListTable.value.refresh()
                } else {
                  debugger;
                  Vue.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Something went wrong',
                      icon: 'EditIcon',
                      variant: 'danger',
                    },
                  })
                }

              })
              .catch((error) => {
                Vue.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Something went wrong',
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        });

    },
    refetchData() {
      debugger;
      // refPlanListTable.value.refresh()
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
